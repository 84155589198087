import React from "react";
import "./dotsSpinner.scss";

interface dotsSpinnerType {
  className: string;
}

export default function DotsSpinner({ className }: dotsSpinnerType) {
  return (
    <div className={className + " lds-ellipsis absolute"}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
