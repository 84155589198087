import { useState, useEffect, ChangeEvent, useRef } from "react";
import "./collections.scss";
import { PrimaryButton, SecondaryLink } from "../../components/buttons/buttons";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { collectionType } from "../../models/collection.model";
import CollectionsService from "../../services/collections.service";
import { useNavigate } from "react-router-dom";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { useDispatch } from "react-redux";
import CollectionsParserService from "../../services/collectionsParser.service";

interface Props {}

const ImportCollections = (props: Props) => {
  const [title, setTitle] = useState<string>("Import collections");
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [collections, setCollections] = useState<collectionType[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const collectionsParserService = new CollectionsParserService();
  const collectionsService = new CollectionsService();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsCompleted(false);
  }, [false]);

  const feedback = (res: { success: boolean; data: any }) => {
    const toastData: ToastType = {
      title: res.success ? "Collections enregistrée avec succès" : "Erreur",
      texts: [{ text: res.success ? "" : res.data }],
      status: res.success ? "success" : "error",
      position: "topRight",
      autoClose: res.success,
    };
    toastUtil.show(toastData, dispatch);
    if (res.success) {
      navigate("/collections");
    } else {
      if (fileInputRef.current) fileInputRef.current.value = "";
      setIsCompleted(false);
    }
  };

  const clickedFileInput = async () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setIsCompleted(false);
    }
  };
  const fileInputClicked = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files; // FileList | null
    if (files && files.length > 0) {
      const file: File = files[0];
      await collectionsParserService.parseXML(file).then((res: any) => {
        const toastData: ToastType = {
          title: res.success ? "Ready to register !" : "Erreur",
          texts: [
            {
              text: res.success ? res.errors : res.data,
              details: res.success
                ? res.data.length +
                  " collections and " +
                  res.errors.length +
                  " errors"
                : "  " + res.errors.length + " errors",
            },
          ],
          status: res.success ? "success" : "error",
          position: "topRight",
          autoClose: res.success,
        };
        toastUtil.show(toastData, dispatch);

        res.data.map((col:collectionType) => col.label = col.label.replace("&", "et"));

        setIsCompleted(res.success);
        setCollections(res.data);
      });
    }
  };

  const onSave = () => {
    if (collections.length > -1) {
      collectionsService
        .createCollections(collections)
        .then((res) => feedback(res));
    }
  };

  return (
    <div className="flex flex-col">
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">Import collections</h2>
        <SecondaryLink
          label="Annuler"
          to="/collections"
          btnClassname="backButton"
          textClassname="text-sm"
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </header>
      <form className="flex flex-col w-full max-w-xl gap-y-4 rounded-lg bg-white mb-6 p-6 mx-auto shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
        <small className="text-indigo-700">
          * Only .xlsx format is accepted, only 4 columns will be considered:
          arkId, label, trigramme, mother (TRUE/FALSE)
        </small>
        <div className="w-full flex gap-4 justify-center items-center">
          <input
            ref={fileInputRef}
            onClick={clickedFileInput}
            onChange={fileInputClicked}
            className="import text-sm leading-6 font-medium text-zinc-800"
            type="file"
            id="fileList"
            name="fileList"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </div>
        <PrimaryButton
          label="Enregistrer"
          onClick={onSave}
          textClassname="text-md mx-auto"
          btnClassname="mt-8"
          disabled={!isCompleted}
        />
      </form>
    </div>
  );
};

export default ImportCollections;
