import { useState } from "react";
import { PrimaryButton, SecondaryLink } from "../../components/buttons/buttons";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import InputText from "../../components/inputText/inputText";
import Checkbox from "../../components/checkbox/checkbox";
import {
  CodeProjetRessourceModel,
  codeProjetRessourceType,
} from "../../models/codeProjetRessource.model";
import CodeProjetRessourcesService from "../../services/codeProjetRessources.service";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

interface Props {}

const NewCodeProjetRessource = (props: Props) => {
  const [code, setCode] = useState<string>("");

  const codeProjetRessourcesService = new CodeProjetRessourcesService();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSave = () => {
    const codeProjetRessourceValues: codeProjetRessourceType = {
      code,
    };

    const codeModel = new CodeProjetRessourceModel(codeProjetRessourceValues);

    codeProjetRessourcesService
      .createCodeProjetRessource(codeModel)
      .then((res) => {
        const toastData: ToastType = {
          title: res.success ? "Code enregistré avec succès" : "Erreur",
          texts: [{ text: res.success ? "" : res.data }],
          status: res.success ? "success" : "error",
          position: "bottomRight",
          autoClose: res.success,
        };
        toastUtil.show(toastData, dispatch);
        if (res.success) navigate("/codes");
      });
  };

  return (
    <div className="flex flex-col">
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">Nouveau code projet</h2>
        <SecondaryLink
          label="Annuler"
          to="/codes"
          btnClassname="backButton"
          textClassname="text-sm"
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </header>
      <form className="flex flex-col mt-10 w-full max-w-xl gap-y-4 rounded-lg bg-white mb-6 p-6 mx-auto shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
        <InputText
          type="text"
          label="Code"
          id="code"
          classname="w-1/2 mx-auto"
          onChange={(e: any) => setCode(e.target.value)}
        />

        <PrimaryButton
          label="Enregistrer"
          onClick={onSave}
          textClassname="text-md mx-auto"
          btnClassname="mt-8"
          disabled={code === ""}
        />
      </form>
    </div>
  );
};

export default NewCodeProjetRessource;
