import { useState, useEffect } from "react";
import "./collections.scss";
import { PrimaryButton, SecondaryLink } from "../../components/buttons/buttons";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import InputText from "../../components/inputText/inputText";
import Checkbox from "../../components/checkbox/checkbox";
import { CollectionModel, collectionType } from "../../models/collection.model";
import CollectionsService from "../../services/collections.service";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { useDispatch } from "react-redux";

interface Props {}

const NewCollection = (props: Props) => {
  const [title, setTitle] = useState<string>("Nouvelle collection");
  const [idArk, setIdAk] = useState<string>("");
  const [label, setLabel] = useState<string>("");
  const [acronym, setAcronym] = useState<string>("");
  const [isMother, setIsMother] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const collectionsService = new CollectionsService();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.update) {
      setIsUpdate(true);
      const collectionToUpdate: collectionType = location.state.update;
      setIdAk(collectionToUpdate.idArk);
      setLabel(collectionToUpdate.label);
      setAcronym(collectionToUpdate.acronym);
      setIsMother(collectionToUpdate.mother);
      setTitle("Modification de la collection");
    }
  }, [location.state]);

  useEffect(() => {
    setIsCompleted(idArk !== "" && label !== "" && acronym !== "");
  }, [idArk, label, acronym]);

  const feedback = (res: { success: boolean; data: any }) => {
    const toastData: ToastType = {
      title: res.success ? "Collection enregistrée avec succès" : "Erreur",
      texts: [{ text: res.success ? "" : res.data }],
      status: res.success ? "success" : "error",
      position: "bottomRight",
      autoClose: res.success,
    };
    toastUtil.show(toastData, dispatch);
    if (res.success) navigate("/collections");
  };

  const onSave = () => {
    const collectionValues: collectionType = {
      idArk,
      label: label.replace("&", "et"),
      mother: isMother,
      acronym,
    };

    const colModel = new CollectionModel(collectionValues);

    collectionsService[isUpdate ? "updateCollection" : "createCollection"](
      colModel
    ).then((res) => feedback(res));
  };

  return (
    <div className="flex flex-col">
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">{title}</h2>
        <SecondaryLink
          label="Annuler"
          to="/collections"
          btnClassname="backButton"
          textClassname="text-sm"
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </header>
      <form className="flex flex-col w-full max-w-xl gap-y-4 rounded-lg bg-white mb-6 p-6 mx-auto shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
        <small className="text-indigo-700">* Tous les champs sont requis</small>
        <Checkbox
          id="mother"
          classname="font-medium self-end"
          label="Collection mère"
          checked={isMother}
          onChange={(e) => setIsMother(e.target.checked)}
        />

        <InputText
          type="text"
          label="Label"
          id="label"
          classname="w-full"
          value={label}
          onChange={(e: any) => setLabel(e.target.value)}
        />
        <div className="w-full flex gap-4 justify-center items-center">
          <InputText
            type="text"
            label="ark ID"
            id="idArk"
            placeholder="ark:/86527/..."
            classname="w-1/2"
            value={idArk}
            onChange={(e: any) => setIdAk(e.target.value)}
          />
          <InputText
            type="text"
            label="Trigramme"
            id="acronym"
            classname="w-1/2"
            value={acronym}
            onChange={(e: any) => setAcronym(e.target.value)}
          />
        </div>
        <PrimaryButton
          label="Enregistrer"
          onClick={onSave}
          textClassname="text-md mx-auto"
          btnClassname="mt-8"
          disabled={!isCompleted}
        />
      </form>
    </div>
  );
};

export default NewCollection;
