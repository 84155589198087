import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storageSession from "redux-persist/lib/storage/session";

import toastSlice from "./toast.slice";
import spinnerSlice from "./spinner.slice";

export const reducers = combineReducers({
  toast: toastSlice,
  spinner: spinnerSlice,
});
const persistConfig = {
  key: "root",
  storage: storageSession,
  blacklist: ["accessibility", "header"],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
