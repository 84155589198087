import { useLocation } from "react-router-dom";
function CatSansCat() {
  let location = useLocation();
  return (
    <div className="bg-zinc-800 w-full h-full flex flex-col gap-y-8 justify-center items-center text-white font-medium">
      <h3 className="text-6xl">404</h3>
      <h2>
        cette page n'existe pas : <code>{location.pathname}</code>
      </h2>
    </div>
  );
}

export default CatSansCat;
