import { Link, useLocation } from "react-router-dom";

function AccessDenied() {
  const location = useLocation();
  const backUrl = location.search.includes("reporter")
    ? "/login"
    : "/subscriptions";
  return (
    <div className="bg-zinc-800 w-full h-full flex flex-col gap-y-8 justify-center items-center text-white font-medium">
      <h3 className="text-6xl">Accès non autorisé</h3>
      <h2>vous ne pouvez pas consulter cette page</h2>
      <Link to={backUrl}>Retour</Link>
    </div>
  );
}

export default AccessDenied;
