import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "./paginate.scss";

export default function PaginatedItems(itemsPerPage: number, items: any[]) {
  const [itemOffset, setItemOffset] = useState<number>(0);
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [endOffset, setEndOffset] = useState<number>(itemOffset + itemsPerPage);

  useEffect(() => {
    if (items.length > 0) {
      if (itemOffset >= items.length) {
        setItemOffset((i) => i - itemsPerPage);
        setEndOffset((e) => e - itemsPerPage);
      }
      setCurrentItems(items.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(items.length / itemsPerPage));
    } else {
      setItemOffset(0);
      setEndOffset(itemOffset + itemsPerPage);
      setCurrentItems(items);
      setPageCount(0);
    }
  }, [items, itemOffset]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
    setEndOffset(newOffset + itemsPerPage);
  };

  const paginate = (
    <ReactPaginate
      nextLabel={
        <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 hover:bg-gray-100">
          <ChevronRightIcon className="w-5 h-5" />
        </span>
      }
      onPageChange={handlePageClick}
      pageRangeDisplayed={1}
      marginPagesDisplayed={1}
      breakLabel="..."
      pageCount={pageCount}
      previousLabel={
        <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 mr-4 hover:bg-gray-100">
          <ChevronLeftIcon className="w-5 h-5" />
        </span>
      }
      renderOnZeroPageCount={null}
      containerClassName="flex items-center justify-center mt-8 mb-4"
      pageClassName="btnPage block border border-solid border-gray-200 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded mr-4"
      breakClassName="mr-4"
      activeClassName="bg-indigo-600 text-white hover:bg-indigo-600"
    />
  );

  return { currentItems: currentItems, paginate: paginate };
}
