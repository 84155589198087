/**
 * @class Defines a collection.
 */

export interface collectionType {
  idArk: string;
  label: string;
  mother: boolean;
  acronym: string;
}

export interface CollectionXLSX{
  arkId: string;
  label: string;
  mother: boolean;
  trigramme: string;
}

export class CollectionModel {
  idArk: string;
  label: string;
  mother: boolean;
  acronym: string;

  constructor(bo: collectionType) {
    this.idArk = bo.idArk;
    this.label = bo.label;
    this.mother = bo.mother;
    this.acronym = bo.acronym;
  }
}
