import { useState } from "react";
import useDeviceDetection, { DEVICE_TYPE } from "../../hook/useDeviceDetection";
import { useSelector, useDispatch } from "react-redux";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import "./toast.scss";
import { closeToast } from "../../store/toast.slice";

export interface ToastTextType {
  text: string;
  details?: string;
}
export interface ToastType {
  title: string;
  texts: ToastTextType[];
  status: string;
  autoClose?: boolean;
  isToastOpen?: boolean;
  blocking?: boolean;
  position?: string;
  buttons?: JSX.Element[];
}

const Toast = () => {
  const toast: ToastType = useSelector((state: any) => state.toast);
  const dispatch = useDispatch();
  return (
    <>
      {toast.isToastOpen && (
        <>
          {toast.blocking && <div className="toastWrapper"></div>}
          <div
            className={
              toast.position +
              (toast?.status === "success"
                ? " bg-emerald-50"
                : toast?.status === "error"
                ? " bg-rose-100"
                : " bg-gray-50") +
              " toast flex justify-between shadow-[0_2px_15px_-3px_rgba(0,0,0,0.14),0_10px_20px_-2px_rgba(0,0,0,0.08)]"
            }
          >
            <div className="flex gap-x-4">
              <div
                className={
                  toast?.texts.length > 0
                    ? "flex-col"
                    : "flex justify-center items-start"
                }
              >
                <div className="flex gap-x-3">
                  {toast?.status === "success" ? (
                    <CheckCircleIcon className="self-center h-6 w-6 text-emerald-400" />
                  ) : toast?.status === "error" ? (
                    <ExclamationCircleIcon className="self-center h-6 w-6 text-rose-500" />
                  ) : (
                    ""
                  )}
                  <p className="text-md font-medium text-zinc-900">
                    {toast?.title}
                  </p>
                </div>
                {toast.texts.length > 0 &&
                  toast.texts.map((txt: ToastTextType) => {
                    return (
                      <div>
                        {txt?.text && (
                          <p className="text-md text-zinc-900 pb mt-2">
                            {txt.text}
                          </p>
                        )}

                        {txt?.details && (
                          <p className="text-sm text-zinc-900">
                            {txt?.details}
                          </p>
                        )}
                      </div>
                    );
                  })}
                {toast.buttons && toast.buttons.length > 0 && (
                  <div className="flex gap-x-4 justify-end mt-4">
                    {toast.buttons.map((button: JSX.Element) => button)}
                  </div>
                )}
              </div>
            </div>
            <button
              className="self-start"
              onClick={() => dispatch(closeToast())}
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>
        </>
      )}
    </>
  );
};
export default Toast;
