import axios from "axios";
export class BaseService {
  apiUrl: string;
  axios: any;

  constructor() {
    let url: string | number = process.env.REACT_APP_API_URL || "";
    //this allows to run the server in nodemon with yarn and not feel the need to make a docker compose

    if (process.env.REACT_APP_ENV_VAR == "local") {
      url = process.env.REACT_APP_API_URL_DEV || "";
    }

    if (this.constructor === BaseService) {
      throw new TypeError(
        'Abstract class "BaseService" cannot be instantiated directly'
      );
    }

    if (url) {
      this.apiUrl = "" + url;
    } else {
      throw new TypeError("Missing environment variable");
    }

    this.axios = axios;
  }

  checkError = (code: Number) => {
    switch (code) {
      case 310:
        return "/login";
        break;
      default:
        null;
    }
  };

  getHeader = () => {
    const token = localStorage.getItem("token");
    const header: { Authorization: string; "Content-Type": string } = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    return header;
  };
}
