import React, { useEffect, useMemo, useState } from "react";
import {
  PrimaryButton,
  PrimaryLink,
  TertiaryButton,
} from "../../components/buttons/buttons";
import { PlusIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { codeProjetRessourceType } from "../../models/codeProjetRessource.model";
import CodeProjetRessourcesService from "../../services/codeProjetRessources.service";
import Table from "../../components/table/table";
import { ColumnDef } from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import { closeToast } from "../../store/toast.slice";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { PaginationModel } from "../../models/pagination.model";
import ReactPaginate from "react-paginate";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "../../components/pagination/paginate.scss";
import { useNavigate } from "react-router";

const codeProjetRessourcesService = new CodeProjetRessourcesService();

const CodeProjetRessources = () => {
  const limit: number = 10;
  const [codeProjetRessources, setRessources] = useState<
    codeProjetRessourceType[]
  >([]);

  const [codeProjetPagination, setPagination] = useState<PaginationModel>({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //const { currentItems, paginate } = PaginatedMongoItems(limit, codeProjetPagination);

  const search = (page: number) => {
    codeProjetRessourcesService
      .getCodeProjetRessources(limit, page)
      .then((res) => {
        if (!res.success) {
          const toastData: ToastType = {
            title: "Erreur de chargement",
            texts: [{ text: res.data }],
            status: "error",
            position: "bottomRight",
            autoClose: false,
          };

          if (res.redirect !== null) {
            return navigate(res.redirect, { state: { error: res.data } });
          }
          return toastUtil.show(toastData, dispatch);
        }

        if (res.data.docs) {
          setRessources(res.data.docs);
          setPagination(res.data);
        }
      });
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    search(event.selected + 1);
  };

  useEffect(() => {
    search(0);
  }, []);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "code",
        header: "Code projet ressource",
        sortingFn: "alphanumeric",
      },
    ],
    []
  );

  const validateDeletion = (code: codeProjetRessourceType) => {
    const toastData: ToastType = {
      title: "Suppression",
      texts: [{ text: "Voulez-vous supprimer le code " + code.code + " ?" }],
      status: "neutre",
      autoClose: false,
      blocking: true,
      position: "middle",
      buttons: [
        <TertiaryButton
          key="no"
          label="Non"
          onClick={() => dispatch(closeToast())}
          textClassname="text-sm px-6 py-2"
        />,
        <div key="yes" onClick={() => dispatch(closeToast())}>
          <PrimaryButton
            label="Oui"
            onClick={() => deleteCode(code.code)}
            textClassname="text-sm"
          />
        </div>,
      ],
    };
    toastUtil.show(toastData, dispatch);
  };

  const deleteCode = (code: string = "") => {
    codeProjetRessourcesService
      .deleteCodeProjetRessource(code)
      .then((res: any) => {
        const toastData: ToastType = {
          title: res.success ? "Code supprimé" : "Erreur",
          texts: [{ text: res.success ? "" : res.data }],
          status: res.success ? "success" : "error",
          position: "bottomRight",
          autoClose: res.success,
        };
        toastUtil.show(toastData, dispatch);

        search(0);
      });
  };

  const actions = {
    delete: validateDeletion,
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center">
        <h2 className="title">Liste des codes projet ressource</h2>

        <PrimaryLink
          label="Ajouter un code projet"
          to="/newCode"
          btnClassname="addButton"
          textClassname="text-sm"
          icon={<PlusIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </div>
      <>
        <Table
          columns={columns}
          data={codeProjetRessources}
          actions={actions}
        />
        <ReactPaginate
          nextLabel={
            <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 hover:bg-gray-100">
              <ChevronRightIcon className="w-5 h-5" />
            </span>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          breakLabel="..."
          pageCount={codeProjetPagination.totalPages || 0}
          previousLabel={
            <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 mr-4 hover:bg-gray-100">
              <ChevronLeftIcon className="w-5 h-5" />
            </span>
          }
          renderOnZeroPageCount={null}
          containerClassName="flex items-center justify-center mt-8 mb-4"
          pageClassName="btnPage block border border-solid border-gray-200 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded mr-4"
          breakClassName="mr-4"
          activeClassName="bg-indigo-600 text-white hover:bg-indigo-600"
        />
      </>
    </div>
  );
};

export default CodeProjetRessources;
