import { forwardRef, useCallback, useEffect, useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { fr } from "date-fns/locale/fr";
import "./subscriptions.scss";
import "react-datepicker/dist/react-datepicker.css";
import SubscriptionsService from "../../services/subscriptions.service";
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryLink,
} from "../../components/buttons/buttons";
import InputText, { InputTextarea } from "../../components/inputText/inputText";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import Checkbox from "../../components/checkbox/checkbox";
import { collectionType } from "../../models/collection.model";
import {
  SubscriptionModel,
  subscriptionType,
} from "../../models/subsrcription.model";
import { ToastTextType, ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addDays, addYears } from "date-fns";
import Select, { ItemType } from "../../components/select/select";
import { codeProjetRessourceType } from "../../models/codeProjetRessource.model";
import CodeProjetRessourcesService from "../../services/codeProjetRessources.service";
import CollectionsService from "../../services/collections.service";

registerLocale("fr", fr);
setDefaultLocale("fr");

const collectionsService = new CollectionsService();

const NewSubscription = () => {
  const [title, setTitle] = useState<string>("Nouvel abonnement");
  const [collections, setCollections] = useState<collectionType[]>([]);
  const [codes, setCodes] = useState<ItemType[]>([{ id: "0", name: "Aucun" }]);
  const [selectedCollections, setSelectedCollections] = useState<
    collectionType[]
  >([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const __endDate = new Date();
  __endDate.setMonth(7, 15);
  
  if(__endDate < startDate)
    __endDate.setFullYear(startDate.getFullYear() + 1);
  const [endDate, setEndDate] = useState<Date>(__endDate);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 5);
  const [commentaire, setCommentaire] = useState<string>("");
  const [uaiEtab, setUaiEtab] = useState<string>("");
  const [codeProjetRessource, setCodeProjetRessource] = useState<string>("");
  const [isMother, setIsMother] = useState<boolean>(false);
  const [studentLicenceNumber, setStudentLicenceNumber] = useState<Number>(0);
  const [studentLicenceUnlimited, setStudentLicenceUnlimited] =
    useState<boolean>(false);
  const [teacherLicenceNumber, setTeacherLicenceNumber] = useState<Number>(0);
  const [teacherLicenceUnlimited, setTeacherLicenceUnlimited] =
    useState<boolean>(false);
  const [otherLicenceNumber, setOtherLicenceNumber] = useState<Number>(0);
  const [otherLicenceUnlimited, setOtherLicenceUnlimited] =
    useState<boolean>(false);
  const [documentalistLicenceNumber, setDocumentalistLicenceNumber] =
    useState<Number>(0);
  const [documentalistLicenceUnlimited, setDocumentalistLicenceUnlimited] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<Number>(0);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const subscriptionsService = new SubscriptionsService();
  const codeProjetRessourcesService = new CodeProjetRessourcesService();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user: string = localStorage.getItem("currentUser") ?? "";

  const generateSubscriptionId = (col: collectionType) => {
    const date = new Date();
    const americanDate =
      date.getFullYear().toString() +
      (1 + date.getMonth()).toString() +
      date.getDate().toString();
    return "MASKOTT_" + americanDate + "_" + col.acronym + uaiEtab;
  };

  const onSave = async () => {
    const subscriptions: Array<SubscriptionModel> = [];
  
    await selectedCollections.map((col, i) => {
      const createSubscription = (start: Date, end: Date, suffix: string) => {
        const xmlValues: subscriptionType = {
          idUser: JSON.parse(user).email,
          idAbonnement: isUpdate
            ? location.state.update.idAbonnement
            : generateSubscriptionId(col) + suffix,
          commentaireAbonnement: commentaire !== "" ? commentaire : col.label,
          idDistributeurCom: "479875718_0000000000000000",
          idRessource: isUpdate
            ? location.state.update.idRessource
            : "ark:/86527/" + col.idArk,
          typeIdRessource: "ark",
          libelleRessource: isUpdate
            ? location.state.update.libelleRessource
            : col.label,
          debutValidite: start.toISOString(),
          finValidite: end.toISOString(),
          uaiEtab,
          categorieAffectation: "transferable",
          typeAffectation: col.mother ? "ETABL" : "INDIV",
          nbLicenceGlobale: isUpdate
            ? location.state.update.nbLicenceGlobale
            : col.mother
            ? "ILLIMITE"
            : "",
          publicCibleStudent: studentLicenceNumber > 0 || studentLicenceUnlimited,
          publicCibleTeacher: teacherLicenceNumber > 0 || teacherLicenceUnlimited,
          publicCibleOther: otherLicenceNumber > 0 || otherLicenceUnlimited,
          publicCibledocumentaliste:
            documentalistLicenceNumber > 0 || documentalistLicenceUnlimited,
        };
  
        if (xmlValues.nbLicenceGlobale !== "ILLIMITE") {
          xmlValues.nbLicenceEnseignant = teacherLicenceUnlimited
            ? "ILLIMITE"
            : Number(teacherLicenceNumber);
          xmlValues.nbLicenceEleve = studentLicenceUnlimited
            ? "ILLIMITE"
            : Number(studentLicenceNumber);
          xmlValues.nbLicenceAutrePersonnel = otherLicenceUnlimited
            ? "ILLIMITE"
            : Number(otherLicenceNumber);
          xmlValues.nbLicenceProfDoc = documentalistLicenceUnlimited
            ? "ILLIMITE"
            : Number(documentalistLicenceNumber);
        }
  
        if (codeProjetRessource !== "0")
          xmlValues.codeProjetRessource = codeProjetRessource;
  
        if (isUpdate) delete xmlValues.uaiEtab;
  
        subscriptions.push(new SubscriptionModel(xmlValues));
      };
  
      const startDateValue = new Date(startDate as Date);
      const endDateValue = new Date(endDate as Date);
      endDateValue.setHours(0,0,0,0)
  
      let currentStartDate = startDateValue;
      let currentEndDate = new Date(
        currentStartDate.getFullYear(),
        7,
        15
      );
      if(currentEndDate < currentStartDate)
        currentEndDate.setFullYear(currentEndDate.getFullYear() + 1);

      let splitting = true;
  
      while (splitting) {
        if (currentEndDate >= endDateValue) {
          currentEndDate = endDateValue;
          splitting = false;
        }

        const suffix =
          currentEndDate === endDateValue
            ? ""
            : `-${currentStartDate.getDay()}`;
            

        createSubscription(currentStartDate, currentEndDate, suffix);

        currentStartDate = new Date(currentEndDate);
        currentStartDate.setDate(currentEndDate.getDate() + 1);
        currentEndDate = new Date(
          currentStartDate.getFullYear() + 1 , 7, 15
        );
      }
    });

    if (subscriptions.length > 0) {
      if (subscriptions.length > 1) {
        createMany(subscriptions);
      } else createOrEditOne(subscriptions[0]);
    }
  };
  const createOrEditOne = async (subModel: SubscriptionModel) => {
    subscriptionsService[
      isUpdate ? "updateSubscription" : "createSubscription"
    ](subModel).then(
      (response: { success: boolean; data: string; details: string }) => {
        const toastData: ToastType = {
          title: response.success ? "Abonnement enregistré" : "Erreur",
          texts: response.success
            ? []
            : [{ text: response.data, details: response.details }],
          status: response.success ? "success" : "error",
          position: "bottomRight",
          autoClose: response.success,
        };
        toastUtil.show(toastData, dispatch);
        if (response.success) {
          navigate("/subscriptions");
        }
      }
    );
  };

  const createMany = async (_subscriptions: Array<SubscriptionModel>) => {
    subscriptionsService.createSubscriptions(_subscriptions).then((res) => {
      let messagesError: ToastTextType[] = [];
      if (!res.success) {
        res.details.map(
          (detail: { idAbonnement: string; error: string; data: string }) => {
            messagesError = [
              {
                text: detail.error + " for " + detail.idAbonnement,
                details: detail.data,
              },
              ...messagesError,
            ];
          }
        );
      }

      const toastData: ToastType = {
        title: res.success ? "Abonnements enregistrés" : "Erreur " + res.data,
        texts: messagesError,
        status: res.success ? "success" : "error",
        position: "bottomRight",
        autoClose: res.success,
      };
      toastUtil.show(toastData, dispatch);
      if (res.success) navigate("/subscriptions");
    });
  };

  const loadData = async () => {
    //recuperate the collections form the service
    collectionsService.getCollections(10000).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res?.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      function compare(a: collectionType, b: collectionType) {
        if (a.mother < b.mother) {
          return 1;
        }
        if (a.mother > b.mother) {
          return -1;
        }
        return 0;
      }
      //sort the collection by mother first
      if (res.data?.docs && res.data.docs.length > 0) {
        res.data.docs.sort(compare);
        setCollections(res.data.docs);

        // recuperate the code projects
        codeProjetRessourcesService
          .getCodeProjetRessources(10000)
          .then((res) => {
            if (!res.success) {
              const toastData: ToastType = {
                title: "Erreur de chargement des codes ressource projet",
                texts: [{ text: res?.data }],
                status: "error",
                position: "bottomRight",
                autoClose: false,
              };
              return toastUtil.show(toastData, dispatch);
            }
            if (res.data.docs) {
              res.data.docs.map((code: codeProjetRessourceType) =>
                setCodes((codes: ItemType[]) => [
                  ...codes,
                  { id: code.code, name: code.code },
                ])
              );
            }
          });
      }
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (selectedCollections.length > 0)
      setIsMother(selectedCollections[0].mother);
  }, [selectedCollections]);

  useEffect(() => {
    if (!isUpdate) {
      setStudentLicenceUnlimited(isMother);
      setTeacherLicenceUnlimited(isMother);
      setOtherLicenceUnlimited(isMother);
    }
  }, [isMother]);

  useEffect(() => {
    setIsCompleted(uaiEtab !== "" && collections.length > 0);
  }, [uaiEtab, collections]);

  useEffect(() => {
    // UPDATE SUBSCRIPTION
    if (location.state?.update) {
      setIsUpdate(true);
      setTitle("Modification de l'abonnement");
      const subscriptionToUpdate: subscriptionType = location.state.update;
      const _col = collections.find(
        (col: collectionType) =>
          "ark:/86527/" + col.idArk === subscriptionToUpdate.idRessource
      );
      if (_col !== undefined) {
        setSelectedCollections([_col]);
      }
      if (subscriptionToUpdate.uaiEtab)
        setUaiEtab(subscriptionToUpdate.uaiEtab);
      if (subscriptionToUpdate.codeProjetRessource)
        setCodeProjetRessource(subscriptionToUpdate.codeProjetRessource);
      setCommentaire(subscriptionToUpdate.commentaireAbonnement);
      setStartDate(new Date(subscriptionToUpdate.debutValidite));
      setEndDate(new Date(subscriptionToUpdate.finValidite));

      if (subscriptionToUpdate.nbLicenceGlobale === "ILLIMITE")
        setIsMother(true);

      if (subscriptionToUpdate.nbLicenceAutrePersonnel)
        subscriptionToUpdate.nbLicenceAutrePersonnel === "ILLIMITE"
          ? setOtherLicenceUnlimited(true)
          : setOtherLicenceNumber(
              subscriptionToUpdate.nbLicenceAutrePersonnel as Number
            );

      if (subscriptionToUpdate.nbLicenceEleve)
        subscriptionToUpdate.nbLicenceEleve === "ILLIMITE"
          ? setStudentLicenceUnlimited(true)
          : setStudentLicenceNumber(
              subscriptionToUpdate.nbLicenceEleve as Number
            );

      if (subscriptionToUpdate.nbLicenceEnseignant)
        subscriptionToUpdate.nbLicenceEnseignant === "ILLIMITE"
          ? setTeacherLicenceUnlimited(true)
          : setTeacherLicenceNumber(
              subscriptionToUpdate.nbLicenceEnseignant as Number
            );

      if (subscriptionToUpdate.nbLicenceProfDoc)
        subscriptionToUpdate.nbLicenceProfDoc === "ILLIMITE"
          ? setDocumentalistLicenceUnlimited(true)
          : setDocumentalistLicenceNumber(
              subscriptionToUpdate.nbLicenceProfDoc as Number
            );

      if (
        subscriptionToUpdate.publicCible &&
        subscriptionToUpdate.nbLicenceGlobale === "ILLIMITE"
      ) {
        setStudentLicenceUnlimited(
          subscriptionToUpdate.publicCible.includes("ELEVE")
        );
        setTeacherLicenceUnlimited(
          subscriptionToUpdate.publicCible.includes("ENSEIGNANT")
        );
        setDocumentalistLicenceUnlimited(
          subscriptionToUpdate.publicCible.includes("DOCUMENTALISTE")
        );
        setOtherLicenceUnlimited(
          subscriptionToUpdate.publicCible.includes("AUTRE PERSONNEL")
        );
      }
      setCurrentPage(1);
    }
  }, [location.state, collections]);

  const updateSelection = useCallback(
    (collection: collectionType) => {
      if (
        selectedCollections.find(
          (col: collectionType, i: number) => col.idArk === collection.idArk
        )
      ) {
        selectedCollections.splice(selectedCollections.indexOf(collection), 1);
        setSelectedCollections([...selectedCollections]);
      } else {
        setSelectedCollections(
          selectedCollections.some((col: collectionType) => col.mother) ||
            collection.mother
            ? [collection]
            : [...selectedCollections, collection]
        );
      }
    },
    [selectedCollections]
  );

  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <InputText
      onClick={onClick}
      ref={ref}
      value={value}
      textStyle="text-center text-md"
      classname="w-28"
    />
  ));

  return (
    <div>
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">{title}</h2>

        <SecondaryLink
          label="Annuler"
          to="/subscriptions"
          btnClassname="backButton"
          textClassname="text-sm"
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </header>
      <form className="flex flex-col w-full max-w-3xl rounded-lg bg-white mb-6 p-6 mx-auto shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] new_sub">
        {currentPage === 0 && !isUpdate && (
          <section className="flex flex-col">
            <div className="font-medium leading-6 text-gray-900 mb-8">
              Selectionnez une ou plusieurs collection(s) :
            </div>
            <ul
              role="list"
              className="max-h-96 overflow-y-auto niceScroll mx-6 divide-y divide-blue-200 rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
            >
              {collections.map((collection: collectionType) => {
                return (
                  <li
                    className={
                      "w-full flex justify-between py-3 px-5 cursor-pointer " +
                      (collection.mother ? "text-indigo-700 " : "") +
                      (selectedCollections.includes(collection)
                        ? "bg-indigo-100"
                        : "") +
                      " hover:bg-indigo-50"
                    }
                    key={collection.idArk}
                    onClick={() => updateSelection(collection)}
                  >
                    {collection.label}
                    {!collection.mother && (
                      <Checkbox
                        id={collection.idArk}
                        label=""
                        onChange={() => {}}
                        checked={selectedCollections.includes(collection)}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
            <div className="flex gap-4 justify-end items-center">
              <SecondaryButton
                label="Suivant"
                onClick={() => setCurrentPage(1)}
                textClassname="text-md mx-auto"
                btnClassname="mt-8"
                disabled={selectedCollections.length < 1}
                icon={
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                }
              />
            </div>
          </section>
        )}
        {currentPage === 1 && (
          <section className="flex flex-col">
            <div className="flex flex-col leading-6 text-zinc-900 mb-8">
              <p className="text-md font-medium">
                Collection{selectedCollections.length > 1 ? "s " : " "}
                sélectionnée
                {selectedCollections.length > 1
                  ? "s : " + selectedCollections.length
                  : " :"}
              </p>
              <div className="flex flex-col font-normal">
                {selectedCollections.map((col, i) => (
                  <p
                    className={
                      (col.mother ? "text-indigo-600" : "text-zinc-950") +
                      " my-1"
                    }
                    key={col.idArk}
                  >
                    {col.label}
                  </p>
                ))}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="gap-y-2">
                <InputText
                  type="text"
                  label="UAI"
                  id="UAI"
                  classname="mb-3 md"
                  value={uaiEtab}
                  disabled={isUpdate}
                  onChange={(e: any) => setUaiEtab(e.target.value)}
                />
                {!isMother && (
                  <Select
                    label="Code projet ressource"
                    items={codes}
                    sort="id"
                    selectedItem={codes.find((code) => code.id === "0")}
                    onChange={(value: ItemType) =>
                      setCodeProjetRessource(value.id)
                    }
                  />
                )}
              </div>
              <InputTextarea
                label="Commentaire"
                id="UAI"
                classname="md"
                row={4}
                value={commentaire}
                placeholder="Nom de la collection"
                onChange={(e: any) => setCommentaire(e.target.value)}
              />
            </div>
            <div className="datePickerWrapper w-full flex justify-between items-center my-6">
              <span className="block text-md font-medium leading-6 text-gray-900 w-6/12">
                Validité :
              </span>
              <div className="mb-[0.125rem] flex items-center min-h-[1.5rem] w-3/12">
                <label
                  className="block px-2 text-sm leading-6 text-gray-900"
                  htmlFor="radioDefault02"
                >
                  début
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  showIcon
                  selectsStart
                  toggleCalendarOnIconClick
                  selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(date) => {
                    if (date) setStartDate(date);
                  }}
                  customInput={<ExampleCustomInput />}
                />
              </div>
              <div className="mb-[0.125rem] flex items-center justify-end min-h-[1.5rem] w-3/12">
                <label
                  className="block px-2 text-sm leading-6 text-gray-900"
                  htmlFor="radioDefault02"
                >
                  fin
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  showIcon
                  selected={endDate}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={addDays(startDate, 1)}
                  maxDate={addYears(startDate, 5)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(date) => {
                    if (date) setEndDate(date);
                  }}
                  customInput={<ExampleCustomInput />}
                />
              </div>
            </div>

            {isMother ||
            location.state?.update.nbLicenceGlobale === "ILLIMITE" ? (
              <>
                <div className="flex gap-1 justify-between items-center mb-6">
                  <span className="block text-md font-medium leading-6 text-gray-900 w-6/12">
                    Nombre de licence globale : ILLIMITE
                  </span>
                </div>
                <div>
                  <span className="block text-md font-medium leading-6 text-gray-900">
                    Publics cible :
                  </span>
                  <Checkbox
                    id="publicStudent"
                    label="Elèves"
                    onChange={(e) =>
                      setStudentLicenceUnlimited(e.target.checked)
                    }
                    defaultChecked
                    checked={studentLicenceUnlimited}
                    classname="pl-10"
                  />
                  <Checkbox
                    id="publicTeacher"
                    label="Enseignants"
                    onChange={(e) =>
                      setTeacherLicenceUnlimited(e.target.checked)
                    }
                    defaultChecked
                    checked={teacherLicenceUnlimited}
                    classname="pl-10"
                  />
                  <Checkbox
                    id="publicOther"
                    label="Autre personnel"
                    onChange={(e) => setOtherLicenceUnlimited(e.target.checked)}
                    defaultChecked
                    checked={otherLicenceUnlimited}
                    classname="pl-10"
                  />
                  <Checkbox
                    id="publicDocumentalist"
                    label="Documentaliste"
                    checked={documentalistLicenceUnlimited}
                    onChange={(e) =>
                      setDocumentalistLicenceUnlimited(e.target.checked)
                    }
                    classname="pl-10"
                  />
                </div>
              </>
            ) : (
              <>
                <span className="block text-md font-medium leading-6 text-gray-900">
                  Publics cible :
                </span>
                <div className="flex gap-1 justify-between items-center mb-2">
                  <p
                    className={
                      (studentLicenceNumber > 0 || studentLicenceUnlimited
                        ? "font-semibold text-indigo-700"
                        : "") + " w-6/12"
                    }
                  >
                    Elèves :
                  </p>
                  <Checkbox
                    id="studentUnlimited"
                    label="Illimité"
                    checked={studentLicenceUnlimited}
                    onChange={(e) =>
                      setStudentLicenceUnlimited(e.target.checked)
                    }
                  />
                  <span className="w-2/12 text-center">ou</span>
                  <InputText
                    type="number"
                    label=""
                    id="studentNumber"
                    placeholder="Nombre"
                    classname="w-2/12 ml-3"
                    disabled={studentLicenceUnlimited}
                    value={studentLicenceNumber.toString()}
                    onChange={(e: any) =>
                      setStudentLicenceNumber(e.target.value)
                    }
                  />
                </div>
                <div className="flex gap-1 justify-between items-center mb-2">
                  <p
                    className={
                      (teacherLicenceNumber > 0 || teacherLicenceUnlimited
                        ? "font-semibold text-indigo-700"
                        : "") + " w-6/12 "
                    }
                  >
                    Enseignants :
                  </p>
                  <Checkbox
                    id="teacherUnlimited"
                    label="Illimité"
                    checked={teacherLicenceUnlimited}
                    onChange={(e) =>
                      setTeacherLicenceUnlimited(e.target.checked)
                    }
                  />
                  <span className="w-2/12 text-center">ou</span>
                  <InputText
                    type="number"
                    label=""
                    id="teacherNumber"
                    placeholder="Nombre"
                    classname="w-2/12 ml-3"
                    value={teacherLicenceNumber.toString()}
                    disabled={teacherLicenceUnlimited}
                    onChange={(e: any) =>
                      setTeacherLicenceNumber(e.target.value)
                    }
                  />
                </div>
                <div className="flex gap-1 justify-between items-center mb-2">
                  <p
                    className={
                      (otherLicenceNumber > 0 || otherLicenceUnlimited
                        ? "font-semibold text-indigo-700"
                        : "") + " w-6/12 "
                    }
                  >
                    Autre personnel :
                  </p>
                  <Checkbox
                    id="otherUnlimited"
                    label="Illimité"
                    checked={otherLicenceUnlimited}
                    onChange={(e) => setOtherLicenceUnlimited(e.target.checked)}
                  />
                  <span className="w-2/12 text-center">ou</span>
                  <InputText
                    type="number"
                    label=""
                    id="otherNumber"
                    placeholder="Nombre"
                    classname="w-2/12 ml-3"
                    value={otherLicenceNumber.toString()}
                    disabled={otherLicenceUnlimited}
                    onChange={(e: any) => setOtherLicenceNumber(e.target.value)}
                  />
                </div>
                <div className="flex gap-1 justify-between items-center mb-2">
                  <p
                    className={
                      (documentalistLicenceNumber > 0 ||
                      documentalistLicenceUnlimited
                        ? "font-semibold text-indigo-700"
                        : "") + " w-6/12 "
                    }
                  >
                    Documentaliste :
                  </p>
                  <Checkbox
                    id="documentalistUnlimited"
                    label="Illimité"
                    checked={documentalistLicenceUnlimited}
                    onChange={(e) =>
                      setDocumentalistLicenceUnlimited(e.target.checked)
                    }
                  />
                  <span className="w-2/12 text-center">ou</span>
                  <InputText
                    type="number"
                    label=""
                    id="documentalistNumber"
                    placeholder="Nombre"
                    classname="w-2/12 ml-3"
                    value={documentalistLicenceNumber.toString()}
                    disabled={documentalistLicenceUnlimited}
                    onChange={(e: any) =>
                      setDocumentalistLicenceNumber(e.target.value)
                    }
                  />
                </div>
              </>
            )}
            <div className="flex gap-4 justify-end items-center">
              <SecondaryButton
                label={isUpdate ? "Annuler" : "Retour"}
                onClick={() =>
                  isUpdate ? navigate("/subscriptions") : setCurrentPage(0)
                }
                textClassname="text-md mx-auto"
                btnClassname="mt-8"
                icon={
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                }
              />
              <PrimaryButton
                label="Enregistrer"
                onClick={onSave}
                textClassname="text-md mx-auto"
                btnClassname="mt-8"
                disabled={!isCompleted}
              />
            </div>
          </section>
        )}
      </form>
    </div>
  );
};

export default NewSubscription;
