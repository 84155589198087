/**
 * @class Defines an subscription.
 */

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];
export interface subscriptionType {
  idUser: string;
  idAbonnement: string;
  commentaireAbonnement: string;
  idDistributeurCom: string;
  idRessource: string;
  typeIdRessource: string;
  libelleRessource: string;
  debutValidite: string;
  finValidite: string;
  uaiEtab?: string;
  codeProjetRessource?: string;
  categorieAffectation: string;
  nbLicenceGlobale?: Number | string;
  typeAffectation: string;
  nbLicenceEnseignant?: Number | string;
  nbLicenceEleve?: Number | string;
  nbLicenceAutrePersonnel?: Number | string;
  nbLicenceProfDoc?: Number | string;
  publicCibleStudent: boolean;
  publicCibleTeacher: boolean;
  publicCibleOther: boolean;
  publicCibledocumentaliste: boolean;
  publicCible?: string[];
}

export class SubscriptionModel {
  idUser: string;
  idAbonnement: string;
  commentaireAbonnement: string;
  idDistributeurCom: string;
  typeIdRessource: string;
  idRessource: string;
  libelleRessource: string;
  debutValidite: string;
  finValidite: string;
  uaiEtab?: string;
  codeProjetRessource?: string;
  categorieAffectation: string;
  typeAffectation: string;
  nbLicenceGlobale?: Number | string;
  nbLicenceEnseignant?: Number | string;
  nbLicenceEleve?: Number | string;
  nbLicenceAutrePersonnel?: Number | string;
  nbLicenceProfDoc?: Number | string;
  publicCibleStudent: boolean;
  publicCibleTeacher: boolean;
  publicCibleOther: boolean;
  publicCibledocumentaliste: boolean;

  constructor(bo: subscriptionType) {
    this.idUser = bo.idUser;
    this.idAbonnement = bo.idAbonnement;
    this.commentaireAbonnement = bo.commentaireAbonnement;
    this.idDistributeurCom = bo.idDistributeurCom;
    this.idRessource = bo.idRessource;
    this.typeIdRessource = bo.typeIdRessource;
    this.libelleRessource = bo.libelleRessource;
    this.debutValidite = bo.debutValidite;
    this.finValidite = bo.finValidite;
    this.uaiEtab = bo.uaiEtab;
    this.codeProjetRessource = bo.codeProjetRessource;
    this.categorieAffectation = bo.categorieAffectation;
    this.typeAffectation = bo.typeAffectation;
    this.nbLicenceGlobale = bo.nbLicenceGlobale;
    this.nbLicenceEnseignant = bo.nbLicenceEnseignant;
    this.nbLicenceEleve = bo.nbLicenceEleve;
    this.nbLicenceAutrePersonnel = bo.nbLicenceAutrePersonnel;
    this.nbLicenceProfDoc = bo.nbLicenceProfDoc;
    this.publicCibleStudent = bo.publicCibleStudent;
    this.publicCibleTeacher = bo.publicCibleTeacher;
    this.publicCibleOther = bo.publicCibleOther;
    this.publicCibledocumentaliste = bo.publicCibledocumentaliste;
  }
}
