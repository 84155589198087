import { setData, openToast, closeToast } from "../store/toast.slice";
import { ToastType } from "../parts/toast/toast";

const toastUtil = {
  show: (
    data: ToastType,
    dispatch: (arg0: {
      payload: any;
      type: "toast/setData" | "toast/openToast" | "toast/closeToast";
    }) => void
  ) => {
    dispatch(setData(data));
    dispatch(openToast());

    if (data.autoClose) setTimeout(() => dispatch(closeToast()), 3000);
  },
};

export default toastUtil;
