/**
 * @class Defines an user.
 */

export interface userType {
  email: string;
  password?: string;
  role: string;
  enterprise: string;
  firstName: string;
  lastName: string;
}

export class UserModel {
  email: string;
  role: string;
  password?: string;
  enterprise: string;
  firstName: string;
  lastName: string;

  constructor(bo: userType) {
    this.email = bo.email;
    this.password = bo.password;
    this.role = bo.role;
    this.enterprise = bo.enterprise;
    this.firstName = bo.firstName;
    this.lastName = bo.lastName;
  }
}
