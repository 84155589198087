import { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { PrimaryButton, SecondaryLink } from "../../components/buttons/buttons";
import Select, { ItemType } from "../../components/select/select";
import "./users.scss";
import InputText from "../../components/inputText/inputText";
import PasswordStrengthBar from "react-password-strength-bar";
import { UserModel, userType } from "../../models/user.model";
import UsersService from "../../services/users.service";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastType } from "../../parts/toast/toast";
import toastUtil from "../../utils/toast.util";

interface Props {}

const NewUser = (props: Props) => {
  const roles: ItemType[] = [
    { id: "1", name: "reporter" },
    { id: "2", name: "subscriber" },
    { id: "3", name: "admin" },
    { id: "4", name: "viewer" }
  ];
  const [title, setTitle] = useState<string>("Nouvel utilisateur");
  const [role, setRole] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordScore, setPasswordScore] = useState<Number>(0);
  const [enterprise, setEnterprise] = useState<string>("");
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);

  const usersService = new UsersService();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.update) {
      setIsUpdate(true);
      const userToUpdate: userType = location.state.update;
      setLastName(userToUpdate.lastName);
      setFirstName(userToUpdate.firstName);
      setEmail(userToUpdate.email);
      setEnterprise(userToUpdate.enterprise);
      setEnterprise(userToUpdate.enterprise);
      const _role = roles.find((item) => item.name === userToUpdate.role);
      if (_role) setRole(_role.name);
      setTitle("Modification de l'utilisateur");
    }
  }, [location.state]);

  const feedback = (res: { success: boolean; data: any; details: string }) => {
    const toastData: ToastType = {
      title: res.success ? "Utilisateur enregistré avec succès" : "Erreur",
      texts: [{ text: res.success ? "" : res.data, details: res.details }],
      status: res.success ? "success" : "error",
      position: "bottomRight",
      autoClose: res.success,
    };
    toastUtil.show(toastData, dispatch);

    if (res.success) navigate("/users");
  };

  const onSave = () => {
    const userValues: userType = {
      firstName,
      lastName,
      role,
      password,
      email,
      enterprise,
    };

    const userModel = new UserModel(userValues);

    if (isUpdate && password === "") delete userModel.password;

    usersService[isUpdate ? "updateUser" : "createUser"](userModel).then(
      (res) => feedback(res)
    );
  };

  useEffect(() => {
    setIsCompleted(
      firstName !== "" &&
        lastName !== "" &&
        email !== "" &&
        enterprise !== "" &&
        (isUpdate
          ? password !== ""
            ? passwordScore >=3
            : true
          : passwordScore >=3)
    );
  }, [firstName, lastName, email, passwordScore, enterprise, password]);

  return (
    <div className="flex flex-col">
      <header className="flex justify-between items-center pr-5">
        <h2 className="title">{title}</h2>
        <SecondaryLink
          label="Annuler"
          to="/users"
          btnClassname="backButton"
          textClassname="text-sm"
          icon={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
      </header>
      <form className="flex flex-col w-full gap-y-6 max-w-xl rounded-lg bg-white mb-6 p-6 mx-auto shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]">
        <small className="text-indigo-700">* Tous les champs sont requis</small>
        <div className="w-full flex gap-4 justify-center items-center">
          <InputText
            type="text"
            label="Nom"
            id="lastName"
            classname="w-1/2"
            value={lastName}
            autocomplete="given-name"
            onChange={(e: any) => setLastName(e.target.value)}
          />
          <InputText
            type="text"
            label="Prénom"
            id="firstName"
            classname="w-1/2"
            value={firstName}
            autocomplete="family-name"
            onChange={(e: any) => setFirstName(e.target.value)}
          />
        </div>
        <div className="w-full flex gap-4 justify-center items-start pt-4">
          <InputText
            type="email"
            label="Email"
            id="email"
            classname="w-1/2"
            value={email}
            autocomplete="email"
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <div className="w-1/2">
            <InputText
              type="password"
              label={isUpdate ? "Nouveau mot de passe" : "Mot de passe"}
              id="password"
              classname="w-full"
              value={password}
              autocomplete="new-password"
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <PasswordStrengthBar
              password={password}
              onChangeScore={(score) => setPasswordScore(score)}
              shortScoreWord="trop court"
              scoreWords={["faible", "faible", "moyen", "bon", "fort"]}
            />
          </div>
        </div>
        <div className="w-full flex gap-4 justify-center items-center">
          <InputText
            type="enterprise"
            label="Entreprise"
            id="enterprise"
            classname="w-1/2"
            value={enterprise}
            autocomplete="organization"
            onChange={(e: any) => setEnterprise(e.target.value)}
          />
          <Select
            label="Rôle"
            items={roles}
            classname="w-1/2"
            selectedItem={roles.find((ro) => ro.name === role)}
            onChange={(value: ItemType) => setRole(value.name)}
          ></Select>
        </div>
        <PrimaryButton
          label="Enregistrer"
          onClick={onSave}
          textClassname="text-md mx-auto"
          btnClassname="mt-8"
          disabled={!isCompleted}
        />
      </form>
    </div>
  );
};

export default NewUser;
