import { Outlet } from "react-router";
import "./App.css";
import Navbar from "./parts/navbar/navbar";
import Toast from "./parts/toast/toast";
import useDeviceDetection from "./hook/useDeviceDetection";
import Footer from "./parts/footer/footer";

function App() {
  const userDevice = useDeviceDetection();

  return (
    <main className={userDevice}>
      <Navbar />
      <section className="bg-zinc-50">
        <Outlet />
        <Footer />
      </section>
      <Toast />
    </main>
  );
}

export default App;
