import { UserModel, userType } from "../models/user.model";
import { StatusCodes } from "http-status-codes";
import { BaseService } from "./base.service";
import { ReturnPagination } from "../models/return.model";
import { PaginationModel } from "../models/pagination.model";

export default class UsersService extends BaseService {
  constructor() {
    super();
  }

  createUser = async (values: userType) => {
    if (!(values instanceof UserModel)) {
      throw new TypeError(
        'The subscription must be an instance of class "UserModel"'
      );
    }

    return await this.axios
      .post(`${this.apiUrl}/api/user`, values, {
        headers: this.getHeader(),
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.CREATED, StatusCodes.OK].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return { data: error.message, success: false };
      });
  };

  updateUser = async (values: userType) => {
    return await this.axios
      .put(`${this.apiUrl}/api/user/`, values, {
        headers: this.getHeader(),
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response?.status +
            " : " +
            error.response?.data?.error,
          success: false,
          details: error.response?.data?.data,
        };
      });
  };

  deleteUser = async (email: string) => {
    return await this.axios
      .delete(`${this.apiUrl}/api/user/`, {
        headers: this.getHeader(),
        data: {
          email: email,
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if ([StatusCodes.OK, StatusCodes.NO_CONTENT].includes(res.status)) {
          return {
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,
          success: false,
        };
      });
  };

  getUser = async (email: string) => {
    return await this.axios
      .get(`${this.apiUrl}/api/user/`, {
        headers: this.getHeader(),
        params: {
          email: email,
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if (
          [
            StatusCodes.CREATED,
            StatusCodes.NO_CONTENT,
            StatusCodes.ACCEPTED,
            StatusCodes.OK,
          ].includes(res.status)
        ) {
          return {
            data: res.data.data,
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response?.status +
            " : " +
            error.response?.data?.error,
          success: false,
          redirect: this.checkError(error.response?.status),
        };
      });
  };

  getUsers = async (limit: number, page: number = 1, filter: any = {}) => {
    return await this.axios
      .get(`${this.apiUrl}/api/users/`, {
        headers: this.getHeader(),
        params: {
          page: page,
          limit: limit,
          filter: filter,
        },
      })
      .then(async (res: { status: StatusCodes; data: any }) => {
        if (
          [
            StatusCodes.CREATED,
            StatusCodes.NO_CONTENT,
            StatusCodes.ACCEPTED,
            StatusCodes.OK,
          ].includes(res.status)
        ) {
          const returnGet = res.data as ReturnPagination;
          const pagination = returnGet.data as PaginationModel;
          return {
            data: pagination,
            success: true,
          };
        }
        return { data: res.data, success: false };
      })
      .catch((error: any) => {
        return {
          data:
            "Code " +
            error.response.status +
            " : " +
            error.response.data?.error,
          success: false,
          redirect: this.checkError(error.response.status),
        };
      });
  };
}
