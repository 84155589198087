import React, { useEffect, useMemo, useState } from "react";
import {
  PrimaryButton,
  PrimaryLink,
  TertiaryButton,
} from "../../components/buttons/buttons";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import SearchBar, { InputType } from "../../components/searchBar/searchBar";
import { collectionType } from "../../models/collection.model";
import CollectionsService from "../../services/collections.service";
import Table from "../../components/table/table";
import { ColumnDef, Row } from "@tanstack/react-table";
import { ToastType } from "../../parts/toast/toast";
import { useDispatch } from "react-redux";
import toastUtil from "../../utils/toast.util";
import { closeToast } from "../../store/toast.slice";
import { redirect, useNavigate } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "../../components/pagination/paginate.scss";
import "./collections.scss";
import { PaginationModel } from "../../models/pagination.model";
import ReactPaginate from "react-paginate";

const collectionsService = new CollectionsService();

const Collections = () => {
  const [reload, setReaload] = useState<boolean>(true);
  const [collections, setCollections] = useState<collectionType[]>([]);
  const [textFilter, setTextFilter] = useState<string>("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const limit: number = 10;

  const [collectionPagination, setPagination] = useState<PaginationModel>({});

  const collectionSearchInputs: InputType[] = [
    { name: "Nom", type: "text", onChange: setTextFilter },
  ];

  const search = (page: number) => {
    const filtered = textFilter || undefined;
    collectionsService.getCollections(limit, page, filtered).then((res) => {
      if (!res.success) {
        const toastData: ToastType = {
          title: "Erreur de chargement",
          texts: [{ text: res.data }],
          status: "error",
          position: "bottomRight",
          autoClose: false,
        };

        if (res.redirect !== null) {
          return navigate(res.redirect, { state: { error: res.data } });
        }
        return toastUtil.show(toastData, dispatch);
      }
      if (res.data.docs) {
        setCollections(res.data.docs);
        setPagination(res.data);
        setReaload(false);
      }
    });
  };

  const handlePageClick = (event: { selected: number }) => {
    search(event.selected + 1);
  };

  const filter = () => {
    search(0);
  };

  useEffect(() => {
    if (reload) search(0);
  }, [reload]);

  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "idArk",
        header: "ark ID",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "label",
        header: "Nom",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "acronym",
        header: "Trigramme",
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "mother",
        header: "Mère de famille",
        sortingFn: "alphanumeric",
        cell: (cell) => {
          return cell.row.original.mother ? (
            <CheckIcon className="h-5 w-5 mx-auto" />
          ) : (
            ""
          );
        },
      },
    ],
    []
  );

  const updateCollection = (collection: collectionType) => {
    navigate("/newCollection", { state: { update: collection } });
  };

  const validateDeletion = (collection: collectionType) => {
    const toastData: ToastType = {
      title: "Suppression",
      texts: [
        {
          text:
            "Voulez-vous supprimer la collection " + collection.label + " ?",
        },
      ],
      status: "neutre",
      autoClose: false,
      blocking: true,
      position: "middle",
      buttons: [
        <TertiaryButton
          key="no"
          label="Non"
          onClick={() => dispatch(closeToast())}
          textClassname="text-sm px-6 py-2"
        />,
        <div key="yes" onClick={() => dispatch(closeToast())}>
          <PrimaryButton
            label="Oui"
            onClick={() => deleteCollection(collection.idArk)}
            textClassname="text-sm"
          />
        </div>,
      ],
    };
    toastUtil.show(toastData, dispatch);
  };

  const deleteCollection = (idArk: string = "") => {
    collectionsService.deleteCollection(idArk).then((res: any) => {
      const toastData: ToastType = {
        title: res.success ? "Collection supprimée" : "Erreur",
        texts: [{ text: res.success ? "" : res.data }],
        status: res.success ? "success" : "error",
        position: "bottomRight",
        autoClose: res.success,
      };
      toastUtil.show(toastData, dispatch);

      setReaload(true);
    });
  };

  const actions = {
    update: updateCollection,
    delete: validateDeletion,
  };

  return (
    <div className="flex flex-col collections">
      <div className="flex justify-between items-center">
        <h2 className="title">Liste des collections</h2>
        <div className="buttonsBlock">
          <PrimaryLink
            label="Import collections"
            to="/importCollections"
            btnClassname="addButton"
            textClassname="text-sm"
            icon={<PlusIcon className="h-5 w-5" aria-hidden="true" />}
          />
          <PrimaryLink
            label="Ajouter une collection"
            to="/newCollection"
            btnClassname="addButton"
            textClassname="text-sm"
            icon={<PlusIcon className="h-5 w-5" aria-hidden="true" />}
          />
        </div>
      </div>
      <SearchBar inputs={collectionSearchInputs} action={filter} />
      <>
        <Table
          columns={columns}
          data={collections}
          actions={actions}
          cellStyle="min-w-32"
        />

        <ReactPaginate
          nextLabel={
            <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 hover:bg-gray-100">
              <ChevronRightIcon className="w-5 h-5" />
            </span>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          breakLabel="..."
          pageCount={collectionPagination.totalPages || 0}
          previousLabel={
            <span className="w-10 h-10 flex items-center justify-center rounded bg-gray-200 mr-4 hover:bg-gray-100">
              <ChevronLeftIcon className="w-5 h-5" />
            </span>
          }
          renderOnZeroPageCount={null}
          containerClassName="flex items-center justify-center mt-8 mb-4"
          pageClassName="btnPage block border border-solid border-gray-200 hover:bg-gray-200 w-10 h-10 flex items-center justify-center rounded mr-4"
          breakClassName="mr-4"
          activeClassName="bg-indigo-600 text-white hover:bg-indigo-600"
        />
      </>
    </div>
  );
};

export default Collections;
