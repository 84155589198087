/**
 * @class Defines a codeProjetRessource.
 */

export interface codeProjetRessourceType {
  code: string;
}

export class CodeProjetRessourceModel {
  code: string;

  constructor(bo: codeProjetRessourceType) {
    this.code = bo.code;
  }
}
