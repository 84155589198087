import "./searchBar.scss";
import useDeviceDetection from "../../hook/useDeviceDetection";
import Select from "../select/select";
import InputText from "../inputText/inputText";
import { SecondaryButton } from "../buttons/buttons";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Checkbox from "../checkbox/checkbox";

export interface InputType {
  name: string;
  type: string;
  onChange: (e: any) => void;
  data?: any[];
}
export interface SearchBarPropsType {
  inputs: InputType[];
  action: () => void;
  isDisabled?: boolean;
}

export default function SearchBar({
  inputs,
  action,
  isDisabled,
}: SearchBarPropsType) {
  const userDevice = useDeviceDetection();

  function inputTypeSwitcher(input: InputType): any {
    switch (input.type) {
      case "text":
        return (
          <InputText
            key={input.name}
            label={input.name}
            type="text"
            id={input.name}
            classname="text-zinc-900"
            onChange={(e: any) => input.onChange(e.target.value)}
          ></InputText>
        );
        break;
      case "select":
        if (input?.data && input?.data?.length > 0) {
          return (
            <Select
              key={input.name}
              label={input?.name}
              items={input?.data}
              classname="text-zinc-900"
              onChange={input?.onChange}
            ></Select>
          );
        }
        break;
      case "check":
        return (
          <Checkbox
            key={input.name}
            id={input.name}
            label={input.name}
            classname="font-medium text-zinc-900"
            onChange={input.onChange}
          ></Checkbox>
        );
        break;
    }
  }

  return (
    <div className="searchBar bg-zinc-100">
      {inputs.map((input: InputType) => inputTypeSwitcher(input))}

      <SecondaryButton
        label="Rechercher"
        onClick={action}
        textClassname="text-sm"
        icon={<MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />}
        disabled={isDisabled}
      />
    </div>
  );
}
