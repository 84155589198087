import { StatusCodes } from "http-status-codes";
import { BaseService } from "./base.service";

export default class AuthenticateService extends BaseService {
  constructor() {
    super();
  }

  loginAuth = async (data: { email: string; password: string }) => {
    return await this.axios
      .post(`${this.apiUrl}/auth`, data)
      .then((response: { status: StatusCodes; data: any }) => {
        if (StatusCodes.OK === response.status && response.data) {
          localStorage.setItem(
            "currentUser",
            JSON.stringify(response.data.user)
          );
          localStorage.setItem("token", response.data.token);
          return {
            data: response.data.user,
            success: true,
          };
        } else {
          return { data: response, success: false };
        }
      })
      .catch((error: any) => {
        return { data: error.response?.data.error, success: false };
      });
  };

  logoutAuth = async () => {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
  };
}
