import { createBrowserRouter, redirect } from "react-router-dom";
import App from "../App";
import Collections from "../pages/collections/collections";
import Login from "../pages/login/login";
import Users from "../pages/users/users";
import Subscriptions from "../pages/subscriptions/subscriptions";
import NewSubscription from "../pages/subscriptions/subscriptionNew";
import NewCollection from "../pages/collections/collectionNew";
import NewUser from "../pages/users/userNew";
import CodeProjetRessources from "../pages/codeProjetRessource/codeProjetRessource";
import NewCodeProjetRessource from "../pages/codeProjetRessource/codeProjetRessourceNew";
import CatSansCat from "../pages/catSansCat";
import AccessDenied from "../pages/accessDenied";
import { jwtDecode } from "jwt-decode";
import UsersService from "../services/users.service";
import ImportCollections from "../pages/collections/collectionsImport";

interface PermissionsType {
  reporter: string[];
  subscriber: string[];
}

const PERMISSIONS: PermissionsType = {
  reporter: ["/stats"],
  subscriber: ["/", "/subscriptions", "/newSubscription"],
};

const checkPermission = async (props: any) => {
  const token = localStorage.getItem("token");
  if (token) {
    const page = new URL(props.request.url).pathname;
    const decodedToken: { email: string; exp: number; iat: number } =
      jwtDecode(token);
    const usersService = new UsersService();

    const checkAccess = await usersService
      .getUser(decodedToken.email)
      .then((res) => {
        if (res.success && res.data) {
          const currentUser = res.data;
          const role = currentUser.role;
          // ensure authentic data
          localStorage.setItem("currentUser", JSON.stringify(currentUser));

          if (role !== "admin") {
            const checkPage =
              PERMISSIONS[role as keyof PermissionsType].includes(page);

            return checkPage ? null : redirect("/accessDenied?" + role);
          }

          return null;
        } else return redirect("/login");
      });

    return checkAccess;
  } else return redirect("/login");
};

const checkAuth = async () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return redirect("/login");
  }
  return null;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: checkAuth,
    children: [
      { path: "", loader: checkPermission, element: <Subscriptions /> },
      {
        path: "subscriptions",
        loader: checkPermission,
        element: <Subscriptions />,
      },
      {
        path: "newSubscription",
        loader: checkPermission,
        element: <NewSubscription />,
      },
      {
        path: "importCollections",
        loader: checkPermission,
        element: <ImportCollections />,
      },
      {
        path: "collections",
        loader: checkPermission,
        element: <Collections />,
      },
      {
        path: "newCollection",
        loader: checkPermission,
        element: <NewCollection />,
      },
      { path: "users", loader: checkPermission, element: <Users /> },
      { path: "newUser", loader: checkPermission, element: <NewUser /> },
      {
        path: "codes",
        loader: checkPermission,
        element: <CodeProjetRessources />,
      },
      {
        path: "newCode",
        loader: checkPermission,
        element: <NewCodeProjetRessource />,
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "accessDenied",
    element: <AccessDenied />,
  },
  {
    path: "*",
    element: <CatSansCat />,
  },
]);
