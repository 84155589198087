import { HandRaisedIcon } from "@heroicons/react/24/outline";
export default function Footer() {
  return (
    <footer className="flex justify-center items-center w-full bg-zinc-100 text-zinc-900 px-6 py-4">
      <HandRaisedIcon className="w-4 h-4 inline mr-2" />
      <p>Besoin d'aide ?</p>
      <a
        href="https://maskott.atlassian.net/servicedesk/customer/portal/17"
        target="_blank"
        className="font-medium ml-2 hover:text-indigo-800"
      >
        Contacter le support
      </a>
    </footer>
  );
}
