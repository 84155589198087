import { createSlice } from "@reduxjs/toolkit";

const spinnerModel = {
  btnSpinner: false,
  containerSpinner: false,
};

export const spinnerSlice = createSlice({
  name: "spinner",
  initialState: spinnerModel,
  reducers: {
    showBtnSpinner: (state) => {
      return { ...state, btnSpinner: true };
    },
    hideBtnSpinner: (state) => {
      return { ...state, btnSpinner: false };
    },
    showContainerSpinner: (state) => {
      return { ...state, containerSpinner: true };
    },
    hideContainerSpinner: (state) => {
      return { ...state, containerSpinner: false };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showBtnSpinner,
  hideBtnSpinner,
  showContainerSpinner,
  hideContainerSpinner,
} = spinnerSlice.actions;

export default spinnerSlice.reducer;
