import { createSlice } from "@reduxjs/toolkit";

const toastModel = {
  title: "",
  texts: [],
  status: "",
  position: "middle",
  autoClose: false,
  blocking: false,
  buttons: [],
  isToastOpen: false,
};

export const toastSlice = createSlice({
  name: "toast",
  initialState: toastModel,
  reducers: {
    setData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    openToast: (state) => {
      return { ...state, isToastOpen: true };
    },
    closeToast: (state) => {
      return {
        isToastOpen: false,
        buttons: [],
        blocking: false,
        autoClose: false,
        position: "middle",
        title: "",
        texts: [],
        status: "",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setData, openToast, closeToast } = toastSlice.actions;

export default toastSlice.reducer;
